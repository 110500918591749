import { useState, useEffect } from 'react'
import {
  Flex,
  Progress,
  Spacer,
  Text,
  Center, 
  Stack
} from "@chakra-ui/react"
import { useGetUserData, useEventList, useAllowance } from "../hooks"
import DepositModal from "./DepositModal"
import RefundModal from "./RefundModal"
import ApproveModal from "./ApproveModal"
import { BigNumber } from 'bignumber.js'
import { comma } from "../constant/comma"

export default function EventDetail( props: any ) {
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10**18
  // アカウントアドレス取得
  const account = props.account
  // 親コンポーネントからイベントID取得
  const eventId = props.eventId

  /** ########## コントラクトメソッド ########## */ 
  // イベントデータ取得
  const eventData = useEventList(eventId)
  // イベントに送金した額
  const yourDataList = useGetUserData(account, eventId)
  // approveチェックロジック
  // TODO: approve実装後、false :true に変更
  const flagForModal = parseInt(useAllowance(account)) === 0? false: true
  /** ########## /コントラクトメソッド ########## */ 
  // console.log(eventData)

  // 時間をunixから変換
  const unixTime : number = eventData[6]
  const limitDateUnix: Date = new Date(unixTime * 1000)
  
  // リアルタイムカウントアップ
  const [countTime, setEventCountTime] = useState(0)
  useEffect(() => {
    const id = setInterval(() => {
      setEventCountTime(t => t + 1);
    }, 1000);
    return () => clearInterval(id);
  }, [])

  // カウントダウンの形式変換
  function adjustTimeFormat(time: any, countUpTime: number) {
    // 現在時刻
    let currentTime: Date = new Date()
    // リアルタイムの残り時間
    let differentTime: number = time.getTime() - currentTime.getTime() - countUpTime

    // 日数計算
    let diffDay: number = Math.floor(differentTime/ (1000 * 60 * 60 * 24))
    // 時間計算
    let diffHour: number = (differentTime/ (1000 * 60 * 60 * 24) - diffDay) * 24
    // 分計算
    let diffMinute: number = (diffHour - Math.floor(diffHour)) * 60
    // 秒計算
    let diffSecond: number = (diffMinute - Math.floor(diffMinute)) * 60
    // 00days 00:00:00
    let showingTimer: string = differentTime > 0
    ? diffDay 
    + 'days ' 
    + ('00' + Math.floor(diffHour)).slice(-2) 
    + ':' 
    + ('00' + Math.floor(diffMinute)).slice(-2) 
    + ':' 
    + ('00' + Math.round(diffSecond)).slice(-2)
    : '00:00:00'
    return showingTimer
  }

  // 月表示
  // 一旦封印
  // const months :string[] = [
  //   "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"
  // ]

  // 送金合戦スタート
  const fCFSTimer = limitDateUnix.getDate() 
  + "/" 
  // + months[limitDateUnix.getMonth()]
  + (limitDateUnix.getMonth() + 1) // 一旦日本時間
  + "/" 
  + limitDateUnix.getFullYear() 
  + ' ' 
  + limitDateUnix.toLocaleTimeString()

  // ########## BigNumber.jsを利用して浮動小数点のバグを回避 ##########
  // 達成額（max）
  const maxAmount = new BigNumber(parseInt(eventData[3]))
  const convertedMaxAmount = maxAmount.div(convertedNum).toFixed()

  // 個人の送金額上限（allocation）
  const allocation = new BigNumber(parseInt(eventData[4]))
  const convertedAllocation = allocation.div(convertedNum).toFixed()

  // 送金額（deposit）
  const depositAmount = new BigNumber(parseInt(yourDataList[0]))
  const convertedDeposit = depositAmount.div(convertedNum).toFixed()

  // 達成額までの金額（left）
  const hardCap = new BigNumber(parseInt(eventData[3]))
  const depositTotal = new BigNumber(parseInt(eventData[2]))
  const leftDeposit = new BigNumber(hardCap.toFixed()).minus(depositTotal.toFixed())
  const convertedLeftDeposit = leftDeposit.div(convertedNum).toFixed()
  // ########## /BigNumber.jsを利用して浮動小数点のバグを回避 ##########

  // 表示用にセット
  const eventDetailData = [
    fCFSTimer, // FCFSTimer
    convertedMaxAmount, // max（hardCap）
    parseInt(eventData[2]) / parseInt(eventData[3]) * 100, // 集金率
    convertedAllocation, // allocation（maxContribut）
    convertedDeposit, // depositAmount
    adjustTimeFormat(limitDateUnix, countTime), // timer
    convertedLeftDeposit, // Left(depositTotal)
    eventData[0] // eventName
  ]

  
  return account ? (
    <Flex direction="column" spacing={2} mt="12" mb="12">
      <Flex m="auto">
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ eventDetailData[7] }</Text>
        </Center>
      </Flex>
      <Flex>
        <Center>
          <Text p="4" fontSize="2xl" color="white">FCFS Starts</Text>
        </Center>
        <Spacer />
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ eventDetailData[0] }</Text>
        </Center>
      </Flex>
      <Flex>
        <Center>
          <Text p="4" fontSize="2xl" color="white">Timer</Text>
        </Center>
        <Spacer />
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ eventDetailData[5] }</Text>
        </Center>
      </Flex>
      <Flex>
        <Center>
          <Text p="4" fontSize="2xl" color="white">Max</Text>
        </Center>
        <Spacer />
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ comma(eventDetailData[1]) } BUSD</Text>
        </Center>
      </Flex>
      <Flex>
        <Center>
          <Text p="4" fontSize="2xl" color="white">Left</Text>
        </Center>
        <Spacer />
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ comma(eventDetailData[6]) } BUSD</Text>
        </Center>
      </Flex>
      <Progress colorScheme="pink" height="32px" value = { Number(eventDetailData[2]) } />
      <Flex>
        <Center>
          <Text p="4" fontSize="2xl" color="white">Allocation</Text>
        </Center>
        <Spacer />
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ comma(eventDetailData[3]) } BUSD</Text>
        </Center>
      </Flex>
      <Flex>
        <Center>
          <Text p="4" fontSize="2xl" color="white">Deposit</Text>
        </Center>
        <Spacer />
        <Center>
          <Text p="4" fontSize="2xl" color="white">{ comma(eventDetailData[4]) } BUSD</Text>
        </Center>
      </Flex>

      {
        flagForModal
        ? <Stack direction="row" spacing={4} align="center">
            <DepositModal w="100%" eventId = { eventId } />
            <RefundModal w="100%" eventId = { eventId } depositAmount = { depositAmount } />
          </Stack>
        : <ApproveModal />
      }
    </Flex>
  ): <></>
}