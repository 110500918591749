import {
  Flex,
  Text
} from "@chakra-ui/react";
import { useGetAllUserData } from "../hooks";
import { BigNumber } from 'bignumber.js';
import { comma } from "../constant/comma"

export default function MainItem (props: any) {
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10**18
  const account  = props.account
  const yourDataList = useGetAllUserData(account)

  let sumDeposit = 0
  if (yourDataList && yourDataList[0]) {
    yourDataList[0].map( function (val: any) {
      const deposit = new BigNumber(parseInt(val[2]))
      const convertedDeposit = parseInt(deposit.toFixed()) / convertedNum
      // console.log(convertedDeposit)
      /**
       * valの中身
       * 0: イベントID
       * 1: イベント名
       * 2: アカウントユーザーの送金総額
       */
      sumDeposit += convertedDeposit
      return sumDeposit
    })
  }

  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        Total deposit
      </Text>
      <Text color="white" fontSize="4xl">
        { comma(sumDeposit) } BUSD
      </Text>
    </Flex>
  );
}